import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { formatUrl } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class PitProWrapper extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    static defaultProps = {
        content: {},
    };

    render() {
        // console.log('pitProWrapper=', this.props);
        const { content } = this.props;

        const oddData = [content[0], content[2], content[4]];
        const evenData = [content[1], content[3], content[5]];

        // console.log(oddData, evenData);
        const wrapper = (item, index) => {
            return (
                <div className={index === 2 ? styles.prosItem1 : styles.prosItem} key={item}>
                    <div className={styles.zk_tu}>
                        <a href={formatUrl(oddData[item].url)} target="_blank">
                            <img src={oddData[item].thumbnail} title="" rel={rel} />
                        </a>
                    </div>
                    <a href={formatUrl(oddData[item].url)} target="_blank" title="" rel={rel} className={styles.block}>
                        <div className={styles.zk_tit}>{oddData[item].title}</div>
                    </a>
                    <div className={styles.zk_h}>
                        <h2>
                            <a href={formatUrl(evenData[item].url)} target="_blank" title="" rel={rel}>
                                {evenData[item].title}
                            </a>
                        </h2>
                        <p>{evenData[item].summary}</p>
                    </div>
                </div>
            );
        };

        return (
            <div className={styles.content}>
                {[0, 1, 2].map((item, index) => {
                    return wrapper(item, index);
                })}
            </div>
        );
    }
}

export default errorBoundary(PitProWrapper);
