import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class BottmChannelInfo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
    };

    static defaultProps = {
        content: {},
	};

	/**
	 * 插入html
	 */
	createChannelInfo = () => {
        return { __html: this.props.content };
    };

    render() {

        return (
			<div>
				<div dangerouslySetInnerHTML={this.createChannelInfo()} />
			</div>
		);
    }
}

export default errorBoundary(BottmChannelInfo);
