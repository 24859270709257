import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

import ObNewsItem from '../../../components/obNewsItem';

class HotNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        // console.log('hotnews=', this.props);
        const { content } = this.props;

        const {
            pitObservationNo1,
            pitObservationNo2,
            pitObservationNo3,
            pitSelectedPool,
            pitFreshNews,
            pitWorldData,
        } = content;

        // 逆序排列新闻
        pitFreshNews.sort((a, b) => {
            return Date.parse(b.newsTime) - Date.parse(a.newsTime);
        });

        // 与左侧智库观察模块做排重
        // const filterbasis = [pitObservationNo1[0].id, pitObservationNo2[0].id ? pitObservationNo2[0].id : null, pitObservationNo3[0].id];
        const filterbasis = [];

        if (pitObservationNo1) {
            filterbasis.push(pitObservationNo1[0] ? pitObservationNo1[0].id : '');
        }
        if (pitObservationNo2) {
            filterbasis.push(pitObservationNo2[1] ? pitObservationNo2[1].id : '');
        }
        if (pitObservationNo3) {
            filterbasis.push(pitObservationNo3[0] ? pitObservationNo3[0].id : '');
        }

        const filterNews = pitWorldData.filter(item => {
            return filterbasis.indexOf(item.id) === -1;
        });

        const pitSelectedPools = pitSelectedPool.slice(0, 2);

        const dailyNews = filterNews.slice(0, 11);

        return (
            <div className={styles.col_w1000}>
                {/* 智库频道-智库观察 */}
                <div className={styles.col_left}>
                    <div className={styles.title_left}>
                        <a href="https://news.ifeng.com/shanklist/3-245389-/" target="_blank" title="more" rel={rel}>
                            More
                        </a>
                    </div>
                    <div>
                        {/* 智库频道-智库观察-一级精品 */}
                        {pitObservationNo1.length > 0 ? (
                            <ObNewsItem pitObservation={pitObservationNo1} index={0} />
                        ) : null}
                        {/* 智库频道-智库观察-二级精品 */}
                        {pitObservationNo2.length > 0 ? (
                            <ObNewsItem pitObservation={pitObservationNo2} index={1} />
                        ) : null}
                        {/* 智库频道-智库观察-三级精品 */}
                        {pitObservationNo3.length > 0 ? (
                            <ObNewsItem pitObservation={pitObservationNo3} index={2} />
                        ) : null}
                    </div>
                </div>
                {/* 智库动态-精品池 */}
                <div className={styles.col_m}>
                    <div className={styles.title_m}>
                        <a
                            href={'http://pit.ifeng.com/listpage/71011/1/list.shtml'}
                            target="_blank"
                            title="more"
                            rel={rel}>
                            More
                        </a>
                    </div>
                    <div className={styles.space26} />
                    {pitSelectedPools.map(item => (
                        <div className={styles.tp} key={item.id}>
                            <a href={formatUrl(item.url)} target="_blank" title="">
                                <img
                                    src={formatImage(item.thumbnails.image[0].url, 260, 183)}
                                    width="260"
                                    height="183"
                                />
                            </a>
                            <div className={styles.tp_bj} />
                            <div className={styles.tp_zi}>
                                <a href={formatUrl(item.url)} target="_blank" title="">
                                    {item.title}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                {/* 智库频道-智库日报 */}
                <div className={styles.col_right}>
                    <div className={styles.title_r}>
                        <a href="https://news.ifeng.com/c/special/81iPSl49iNc" target="_blank" title="more" rel={rel}>
                            More
                        </a>
                    </div>
                    <div className={styles.col_r_wrapper}>
                        <ul>
                            {dailyNews.map(item => (
                                <li key={item.id}>
                                    <a href={formatUrl(item.url)} target="_blank">
                                        {item.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(HotNews);
