import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const sliderTmpl = function sliderTmpl(item) {
            return (
                <div className={styles.li}>
                    <a className={styles.blockA} href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} style={{ width: '100%', height: 539 }} />
                        <div className={styles.bg}>
                            <span className={styles.new}>
                                <img
                                    src="http://y0.ifengimg.com/fe/gjzk/images/new_3412ab69.jpg"
                                    width="40"
                                    height="22"
                                />
                            </span>
                            <p className={styles.title}>{item.title}</p>
                            <p className={styles.summary}>{item.summary}</p>
                        </div>
                    </a>
                </div>
            );
        };

        const sliderConfig = {
            arrows: true,
            dots: true,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            unitSize: 1000,
            speed: 500,
            infinite: true,
            extension: 2,
            padding: 1,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl,
        };

        return (
            <div className={styles.sliderContainer}>
                <div className={styles.sliderWraper}>
                    <Slides content={content} config={sliderConfig} />
                </div>
            </div>
        );
    }
}
export default errorBoundary(Slider);
