import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { formatUrl } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class ObNewsItem extends React.PureComponent {
    static propTypes = {
        pitObservation: PropTypes.array,
        index: PropTypes.number,
    };

    static defaultProps = {
        pitObservation: [],
    };

    render() {
        const { pitObservation, index } = this.props;

        return (
            <div className={styles.newsItem}>
                <h1>
                    <a href={formatUrl(pitObservation[index].url)} target="_blank" title="" rel={rel}>
                        {pitObservation[index].title}
                    </a>
                </h1>
                <div className={styles.zz}>
                    <span>
                        作者：<b>{pitObservation[index].author ? pitObservation[index].author : ''}</b>
                    </span>
                    <span>
                        时间：<b>{pitObservation[index].newsTime}</b>
                    </span>
                    <p>{pitObservation[index].summary}</p>
                </div>
            </div>
        );
    }
}

export default errorBoundary(ObNewsItem);
