import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';

import Slider from './slider';
import HotNews from './hotNews';
import PitProducts from './pitProducts';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        const { pitSlider, pitProducts, pitAchievement, pitData } = content;

        return (
            <div className={styles.col_wbf}>
                <Chip
                    id="55018"
                    type="recommend"
                    title="国际智库轮播图"
                    groupName="正文"
                    content={pitSlider}
                    translate="handlePitSliderData">
                    <Slider />
                </Chip>
                <div className={styles.space30} />
                <div>
                    <div className={styles.col_w1000}>
                        <HotNews content={content} />
                        <div className={styles.space30} />
                        <PitProducts content={{ pitProducts, pitAchievement, pitData }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
