import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';

import PitProWrapper from '../../../components/pitProWrapper';

class PitProducts extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        pitProducts: PropTypes.array,
        pitAchievement: PropTypes.array,
        pitData: PropTypes.array,
    };

    render() {
        const { pitProducts, pitAchievement, pitData } = this.props.content;

        return (
            <div className={styles.col_1000}>
                <div>
                    <div className={styles.zkcp_title}>
                        <img src="http://y0.ifengimg.com/fe/gjzk/images/zkcg_e1184df0.jpg" width="1000" height="29" />
                    </div>
                    <div className={styles.space31} />
                    <Chip
                        id="55020"
                        type="recommend"
                        title="智库成果"
                        groupName="智库产品"
                        content={pitAchievement}
                        translate="handlePitProductsData">
                        <PitProWrapper />
                    </Chip>
                    <div className={styles.space31} />
                </div>
                {/* 智库大数据 */}
                <div>
                    <div className={styles.zkcp_title}>
                        <img src="http://y0.ifengimg.com/fe/gjzk/images/zksj_898c37b1.jpg" width="1000" height="29" />
                    </div>
                    <div className={styles.space31} />
                    <Chip
                        id="55021"
                        type="recommend"
                        title="智库大数据"
                        groupName="智库产品"
                        content={pitData}
                        translate="handlePitProductsData">
                        <PitProWrapper />
                    </Chip>
                    <div className={styles.space31} />
                </div>
            </div>
        );
    }
}

export default PitProducts;
