import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import Layout from './layout/';

/* eslint-disable */
const slicePitFreshNews = allData.pitFreshNews.slice(0, 15);

allData.pitFreshNews = slicePitFreshNews;

allData.pitWorldData = allData.worldData.modules[2].data.articleList;

ReactDOM.render(<Layout content={allData} />, document.getElementById('root'));
/* eslint-enable */
