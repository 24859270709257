import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from '@ifeng-fe/container_channel/common/client/components/nav';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';

/**
 * 定义 Header 组件
 */
class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div className={styles.main_nav} key="topNav">
                <div className={styles.nav_box}>
                    <Chip id="20002" type="struct" title="导航" groupName="头部" content={content}>
                        <Nav limit={18} />
                    </Chip>
                </div>
                <div className={styles.userInfo}>
                    <NoSSR>
                        <UserInfo />
                    </NoSSR>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Header);
